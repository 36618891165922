import React from 'react';
import ImageCard from './imgCard';

export default function articleSection() {
    return (
        <div>
            <div>
                <h1 className = "album-heading">Some of best Albums</h1>
                <h2 className = "album-subheading">Connect with the Soul</h2>
            </div>
            <div className = "cardLayout">
                <ImageCard />
            </div>
        </div>
        
      );
}