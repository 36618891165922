import React from 'react';
import './heroSlider.scss';
import cardata from "../json-data/cardData.json";
// import HeroImgThird from "../images/heroimg3.jpg";


export default function ImageCard() {
    return (
        <div className="columns is-desktop">
           {cardata.map((cardDetails) => {
               return(
                <div class="column">
                <div className="card">
                <div className="card-image">
                    <figure className="image is-4by3">
                        <img src={ cardDetails.img } alt="Placeholder image" />
                    </figure>
                </div>
                <div className="card-content">
                <div className="media">
                    <div className="media-content">
                        <p className="title is-4">{cardDetails.heading}</p>
                    </div>
                </div>
                
                <div className="content">
                   {cardDetails.content}
                    <br />
                </div>
                <button className = "button is-warning">Get Now</button>
                </div>
            </div>
        </div>)
           })}
        </div>
        
      );
}
