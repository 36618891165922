import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './heroSlider.scss';
// import heroimg from "../images";
import Herojson from "../json-data/heroContent.json";

export default function HeroSlider() {
    var settings = {
      autoplay: true,
      autoplaySpeed: 5000,
      dots: true,
      infinite: true,
      speed: 1000,
      fade: true,
      slide: 'div',
      cssEase: 'linear'
    };
    return (
      <Slider {...settings}>
          { Herojson.map((heroDetails) => {
            // console.log(`../images/${heroDetails.img}`);
            return(
            <div className="heroDiv column is-12">
              <img className = "heroimg" src={ heroDetails.img }/>
              <span className="heroText">{ heroDetails.title }</span>
              <div className="column is-12">
                <button className="heroBtn button is-info is-rounded is-medium">Explore</button>
              </div>
            </div>)
          }) 
         }
      </Slider>
    );
  }
