import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./quotestyle.scss";
import QuoteJson from "../json-data/quotedata.json";
import quoteOne from "../images/actorImg/download.jpg";


export default class SimpleSlider extends Component {
  render() {
    const settings = {
        autoplay: true,
        autoplaySpeed: 8000,
        dots: true,
        infinite: true,
        speed: 1000,
        fade: true,
        slide: 'div',
        cssEase: 'linear'
    };
    return (
      <div className="quotesection">
    <Slider {...settings}>
          { QuoteJson.map((quoteDetails) => {
            // console.log(quoteDetails);
            return(
                <div className="quoteDiv">
                    <img className="quoteimg" src={ quoteOne }/>
                    <h1 className="quoteHeading">{ quoteDetails.title }</h1>
                    <h3 className="qutoteSaid">{ quoteDetails.saidby }</h3>
              </div>)
          }) 
         }
    </Slider>
      </div>
    );
  }
}