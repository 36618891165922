import React from 'react';
import Article from './articleSection';
import QuoteSlider from "./quoteslider";
import {
	FaSass,
	FaHtml5,
	FaReact,
	FaMobileAlt,
	FaRocket,
	FaOsi
} from 'react-icons/fa';

import './style.scss';
import Emoji from './emoji';

const Midsection = () => (
	<div>
		<section className="section">
			<Article />
			<QuoteSlider/>
		</section>
	</div>
);

export default Midsection;
