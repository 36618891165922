import React from 'react';
import { FaGithub } from 'react-icons/fa';

import './style.scss';

import gatsbyLogo from '../images/gatsby-icon.png';
import bulmaLogo from '../images/bulma-logo.png';
import Navbar from './navbar';


const Header = ({ siteTitle }) => (
	<section className="hero gradientBg">
		<Navbar />
		
	</section>
);

export default Header;
